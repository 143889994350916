$lightbackground: #EDDAD4;
$darkbackground: #DCB4A8;

body{
    background-color: $darkbackground;
}

#container{
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
}

#input{
    padding-left: 2%;
    padding-right: 2%;
}

#editor{
    background-color:$lightbackground;
    border-radius: 7px;
    border: 2px gray solid;
    padding-left: 10px;
    padding-top: 10px;
    width: 35vw;
    height: 77vh;
}

#preview{
    margin-left: 2%;
    margin-right: 2%;
    background-color: $lightbackground;
    border-radius: 7px;
    border: 2px gray solid;
    width: 33vw;
    height: 77vh;
    padding-left: 10px;
    padding-top: 10px;
    overflow-y:auto;
}

#titleArea{
    width: 100%;
    justify-content: center;
    display: flex;
}